import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import ERC20_ABI from '../constants/abis/erc20.json'
import ROUTE_ABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
const MCP = require('mcp.js')
const Web3 = require('web3')
const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

export class NoEthereumProviderError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'No Ethereum provider was found on window.ethereum.'
  }
}

export class UserRejectedRequestError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'The user rejected the request.'
  }
}

export class Aleconnector extends AbstractConnector {
  private active: boolean

  constructor(kwargs: AbstractConnectorArguments) {
    super(kwargs)
    this.handleNetworkChanged = this.handleNetworkChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.active = false
  }

  private async handleAccountsChanged(accounts: string) {
    //this.emitUpdate({ account: accounts })
  }

  private async handleNetworkChanged(networkId: string | number) {
    //this.emitUpdate({ chainId: networkId, provider: new CustomProvider() })
  }

  async activate(): Promise<ConnectorUpdate<string | number>> {
    console.log("====== activate")
    const provider = (window as any).aleereum
    if (!provider) {
      throw new NoEthereumProviderError()
    }
    if (!provider.isConnected || provider.islocked || this.active===false){
      this.active=true;
      provider.on('on_account_change', this.handleAccountsChanged)
      provider.on('on_networkId_change', this.handleNetworkChanged)
      await provider.connect()
    }
    const account = provider.account
    return { provider: new CustomProvider(), chainId: provider.networkId, ...(account ? { account } : {}) }
  }
  getProvider(): Promise<any> {
    const provider = (window as any).aleereum
    return provider
  }
  getChainId(): Promise<string | number> {
    const provider = (window as any).aleereum
    let networkId = provider.networkId
    return networkId
  }
  async getAccount(): Promise<string | null> {
    const provider = (window as any).aleereum
    const account = provider.account
    //this.emitUpdate({ chainId: provider.networkId, provider: new CustomProvider(), account })
    return account
  }
  deactivate(): void {
    console.log("====== deactivate")
    const provider = (window as any).aleereum
    if (provider && provider.removeListener) {
      provider.removeListener('on_networkId_change', this.handleAccountsChanged)
      provider.removeListener('on_account_change', this.handleNetworkChanged)
    }
  }
  public async isAuthorized(): Promise<boolean> {
    return false
  }
}

class CustomProvider {
  isMetaMask = false
  chainId = 0x3cc
  host = ''
  path = ''
  web3: any
  mcp: any
  private nextId = 1
  constructor() {
    this.web3 = new Web3(NETWORK_URL)
    this.mcp = new MCP()
    this.mcp.Contract.setProvider(NETWORK_URL)
  }

  sendAsync(request: any, callback: any): void {
     console.log("------ sendAsync")
    // console.log(JSON.stringify(request));
  }
  send(request: any, callback: any): void {
     console.log("------ send")
    // console.log(JSON.stringify(request));
  }

  fetchRequest(params: any): Promise<any> {
    console.log("==req: "+JSON.stringify(params))
    return new Promise((resolve, reject) => {
      fetch(NETWORK_URL as string, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(data => {
          console.log("==result: "+JSON.stringify(data))
          resolve(data)
        })
        .catch(error => {
          console.log("==error: "+JSON.stringify(error))
          reject(error)
        })
    })
  }

  async request(request: any, params: Array<any>): Promise<any> {
    const root = this
    if (request.method === 'eth_sendTransaction') {
      const rp = request.params
      if (rp && rp.length > 0) {
        const item = rp[0]
        const to = item.to
        const from = item.from
        const data = item.data
        const value = item.value
        if (data) {
          const approve = root.web3.eth.abi.encodeFunctionSignature('approve(address,uint256)')
          if (data.startsWith(approve)) {
            const params = data.replace(approve, '0x')
            const result = root.web3.eth.abi.decodeParameters(['address', 'uint256'], params)
            const Contract = new root.mcp.Contract(ERC20_ABI, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .approve(result[0], result[1])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const addLiquidity = root.web3.eth.abi.encodeFunctionSignature(
            'addLiquidity(address,address,uint256,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(addLiquidity)) {
            const params = data.replace(addLiquidity, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'address', 'uint256', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .addLiquidity(result[0], result[1], result[2], result[3], result[4], result[5], result[6], result[7])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const addLiquidityETH = root.web3.eth.abi.encodeFunctionSignature(
            'addLiquidityETH(address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(addLiquidityETH)) {
            const params = data.replace(addLiquidityETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .addLiquidityETH(result[0], result[1], result[2], result[3], result[4], result[5])
                .sendToBlock({
                  from: from,
                  amount: value
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapExactTokensForTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactTokensForTokens(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapExactTokensForTokens)) {
            const params = data.replace(swapExactTokensForTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            //console.log(JSON.stringify(result))
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapExactTokensForTokens(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  // console.log(JSON.stringify(res));
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapTokensForExactTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactTokensForTokens(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapTokensForExactTokens)) {
            const params = data.replace(swapTokensForExactTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapTokensForExactTokens(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapExactETHForTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactETHForTokens(uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapExactETHForTokens)) {
            const params = data.replace(swapExactETHForTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(['uint256', 'address[]', 'address', 'uint256'], params)
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapExactETHForTokens(result[0], result[1], result[2], result[3])
                .sendToBlock({
                  from: from,
                  amount: value
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapTokensForExactETH = root.web3.eth.abi.encodeFunctionSignature(
            'swapTokensForExactETH(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapTokensForExactETH)) {
            const params = data.replace(swapTokensForExactETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapTokensForExactETH(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapExactTokensForETH = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactTokensForETH(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapExactTokensForETH)) {
            const params = data.replace(swapExactTokensForETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapExactTokensForETH(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapETHForExactTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapETHForExactTokens(uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapETHForExactTokens)) {
            const params = data.replace(swapETHForExactTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(['uint256', 'address[]', 'address', 'uint256'], params)
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapETHForExactTokens(result[0], result[1], result[2], result[3])
                .sendToBlock({
                  from: from,
                  amount: value
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidity = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidity(address,address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(removeLiquidity)) {
            const params = data.replace(removeLiquidity, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidity(result[0], result[1], result[2], result[3], result[4], result[5], result[6])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETH = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETH(address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(removeLiquidityETH)) {
            const params = data.replace(removeLiquidityETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETH(result[0], result[1], result[2], result[3], result[4], result[5])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityWithPermit = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityWithPermit(address,address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)'
          )
          if (data.startsWith(removeLiquidityWithPermit)) {
            const params = data.replace(removeLiquidityWithPermit, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              [
                'address',
                'address',
                'uint256',
                'uint256',
                'uint256',
                'address',
                'uint256',
                'bool',
                'uint8',
                'bytes32',
                'bytes32'
              ],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityWithPermit(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5],
                  result[6],
                  result[7],
                  result[8],
                  result[9],
                  result[10]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETHWithPermit = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETHWithPermit(address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)'
          )
          if (data.startsWith(removeLiquidityETHWithPermit)) {
            const params = data.replace(removeLiquidityETHWithPermit, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256', 'bool', 'uint8', 'bytes32', 'bytes32'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETHWithPermit(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5],
                  result[6],
                  result[7],
                  result[8],
                  result[9]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETHSupportingFeeOnTransferTokens = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETHSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(removeLiquidityETHSupportingFeeOnTransferTokens)) {
            const params = data.replace(removeLiquidityETHSupportingFeeOnTransferTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETHSupportingFeeOnTransferTokens(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETHWithPermitSupportingFeeOnTransferTokens = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)'
          )
          if (data.startsWith(removeLiquidityETHWithPermitSupportingFeeOnTransferTokens)) {
            const params = data.replace(removeLiquidityETHWithPermitSupportingFeeOnTransferTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256', 'bool', 'uint8', 'bytes32', 'bytes32'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETHWithPermitSupportingFeeOnTransferTokens(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5],
                  result[6],
                  result[7],
                  result[8],
                  result[9]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve(res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
        }
      }
    }else{
      return new Promise((resolve,reject)=>{fetch(NETWORK_URL as string,{headers:{Accept:'application/json','Content-Type':'application/json' },method:'POST',body:JSON.stringify({...request,id:this.nextId++,jsonrpc:"2.0"})}).then(response=>response.json()).then(data=>resolve(data.result)).catch(error=>reject(error))})}
    }
  }
